html,body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

/*-----------------------------------------------------------------------------------Spacing and Styling*/

.navbar-allowance {
  padding-top: 70px;
}


a:link, a:visited {
  text-decoration: none;
}


/*-----------------------------------------------------------------------------------Colours*/


.bg-team {
  background-color: #ebe9eb;
}

.bg-hero,
.bg-intro,
.bg-tutoring,
.bg-about,
.bg-courses,
.bg-contact,
.bg-book {
  background-color: #f7f6f7;
}

.bg-testimonials {
  background-color: #F6C76E;
}

.bg-footer {
  background-color: #ffffff;
}

/*-----------------------------------------------------------------------------------Navbar*/

.navbar_logo {
  height: 70px;
}

.navbar-main {
  display: flex;
  height: 90px;
  background-color: #ffffff;
  padding: 0 30px;
  font-family: "Roboto", sans-serif;
}

.active {
  background-color: #ffffff;
}

.navbar-collapse {
  width: 100%;
  background-color: #ffffff;
  padding-left: 10px;
}

/* --------------------------------------------------------------------------------- Socials */

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.instagram {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

/*-----------------------------------------------------------------------------------Hero*/

.hero-pic {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-position: center;
}

.hero-text {
  position: relative;
}

.text-on-image {
  position: absolute;
  text-align: center;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ffffff;
  font-family: "Fuzzy Bubbles", sans-serif;
}

.text-on-image h2 {
    font-size: 60px;
}


/*-----------------------------------------------------------------------------------Intro*/

.intro-title {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  text-align: center;
  font-size: 50px;
  padding: 80px 0 0 0;
}

.intro-subheading {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 30px;
  padding: 50px 0 100px 0;
}

.intro-container {
  width: 80%;
  margin: auto !important;
}

.intro-pic {
  width: 75%;
  margin: auto;
  display: block;
}

.intro-text-title {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  width: 75%;
  margin:auto;
  padding: 20px 0;
}

.intro-text {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  width: 75%;
  margin:auto;
  padding: 20px 0;
}

/*-----------------------------------------------------------------------------------Testimonials*/

.quote {
  color: #ffffff;
  font-family: "Fuzzy Bubbles", sans-serif;
  text-align: center;
}

.quote h5 {
  font-size: 30px;
}

/*-----------------------------------------------------------------------------------DownloadBook*/

.download {
  text-align: center;
  background-image: url("./assets/images/download_image.jpg");
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  align-items: center;
}

.download h3 {
  font-family: "Fuzzy Bubbles", sans-serif;
  font-size: 40px;
}

.download h5 {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  letter-spacing: 1px;
}

.book-link-button {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  background-color: #F6C76E;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 20px;
  margin: 0 10px;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  text-align: center;
}

.book-link-button:hover {
  background-color: #a07018;
  text-decoration: none;
  color: #ffffff;
}

/*-----------------------------------------------------------------------------------About*/

.who-text,
.courses-text {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  padding: 60px 0;
}
.team-info {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  padding-top: 50px;
  margin-bottom: 100px;
}

.team-name {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
}

.team-subjects {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

.team-pic {
  object-fit: cover;
  border-radius: 50%;
  width: 70%;
  margin-bottom: 20px;
  border: 2px solid #555;
}

/*-----------------------------------------------------------------------------------Resources*/

.card {
  font-family: "Roboto", sans-serif;
}

.card-title {
  font-weight: 600;
}

.card-title a {
  text-decoration: none;
  color: #000000;
}

.card-footer {
  text-align: center;
}

.card a:link,
.card a:visited {
  text-decoration: none;
  color: #000000;
}

.headerresources-pic {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-position: bottom;
}

/*-----------------------------------------------------------------------------------TutoringInfo*/

.subject-table {
  margin: 40px 0;
  width: 100%;
}

.pricing-table {
  width: 50%;
  margin-bottom: 100px;
}

.who-text h3 {
  padding-top: 20px;
}

.table-container {
  overflow-x: auto;
}

th, td {
  white-space: nowrap;
  padding: 0 10px;
}

/*-----------------------------------------------------------Contact*/

.contact-heading {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: #000;
}

.contact-form-wrapper {
  padding-bottom: 100px;
  font-family: "Roboto", sans-serif;
}

.contact-form {
  padding: 30px 40px;
  background-color: #f7d699;
  border-radius: 12px;
  width: 40%;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input {
  background-color: #ffffff;
  height: 50px;
  padding-left: 16px;
  font-family: "Inter", sans-serif;
}

.contact-form .form-text-area {
  background-color: #ffffff;
  height: 500px;
  padding-left: 16px;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control::placeholder {
  color: #797d81;
  font-weight: 500;
  opacity: 1;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #797d81;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #797d81;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control:focus {
  border-color: #e66f7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #e66f7b;
}

.description {
  color: #797d81;
  font-size: 18px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.contact-form button {
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color:#F6C76E;
  text-transform: uppercase;
  padding: 10px 0;
  font-weight: 500;
  letter-spacing: 2px;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  transition: 0.3s;
  width: 40%;
  margin: 0 30%;
}

.contact-form button:hover {
  font-weight: 600;
}

.headercontact-pic {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-position: top;
}

/*-----------------------------------------------------------------------------------Footer*/

.footer_logo {
  height: 100%;
  margin: -20px 0;
}

.footer-main {
  display: flex;
  height: 70px;
  font-family: "Roboto", sans-serif;
  text-align: start;
}

.footer-all {
  text-align: center;
  padding: 30px;
}

.footer-links a:link,
.footer-links a:visited {
  text-decoration: none;
  color: #000000;
}

.footer-contact {
  padding-bottom: 20px;
}

/* --------------------------------------------------------------------------------- Media Queries */


@media (max-width: 767px) {
  .hero-pic,
  .headercontact-pic {
    height: 25vh;
  }
  
  .text-on-image h2 {
      font-size: 30px;
  }

  .intro-title {
    font-size: 30px;
  }

  .intro-subheading {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .team-info {
    text-align: center;
  }
  .team-text {
    text-align: start;
  }

  .contact-form {
    width: 80%;
  }

  .pricing-table {
    width: 100%;
  } 

  .download h5 {
    font-size: 20px;
  }

  .download h3 {
    font-size: 30px;
  }

  .quote h5 {
    font-size: 20px;
  }

}